import Web3 from "web3";
import { FIX_DECIMALS } from "../constants";

export const convertPrice = (value) => {
  if (value) {
    return Web3.utils.toWei(value, "ether");
  }
};

export function getEpochsBetween(start, end) {
  let numbers = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }

  return numbers.map(String);
}

export const convertDividePrice = (value) => {
  if (value) {
    return Number(Web3.utils.fromWei(value, "ether")).toFixed(FIX_DECIMALS);
  }
};

export function isValidContractAddress(address) {
  if (!/^0x[a-fA-F0-9]{40}$/.test(address)) {
    return false;
  }

  return true;
}

export function isValidTokenAddress(address) {
  // Check if the address starts with "0x" and is 42 characters long
  if (
    typeof address !== "string" ||
    address.length !== 42 ||
    !address.startsWith("0x")
  ) {
    return false;
  }

  // Check if all characters after "0x" are valid hexadecimal (0-9, a-f)
  return /^[0-9a-fA-F]+$/.test(address.slice(2));
}

export const getErrorMessage = (message) => {
  if (
    message.includes("Sell operation can only be performed once every 24 hours")
  ) {
    return "Sell operation can only be performed once every 24 hours";
  } else if (message.includes("User rejected the request")) {
    return "User rejected the request";
  } else if (message.includes("RPC Request failed")) {
    return "RPC Request failed";
  } else {
    return "Contract execution error";
  }
};
