import React, { useEffect, useState } from 'react'
import TopBar from './components/TopBar'
import Header from './components/Header'
import Footer from './components/Footer'
import { useAccount, useBalance, usePublicClient, useWalletClient } from 'wagmi';
import { erc20Abi, getContract } from "viem";
import { useContract } from './hooks/useContract';
import { toMwei, toWei } from './utils';
import { BURN_PROTOCOL_CONTRACT_BETA, tokenList } from './constants';
import { lycanChainBeta } from './_providers/chain';
import { convertDividePrice, isValidTokenAddress } from './utils/commonFunctions';
import { useWeb3Modal } from '@web3modal/wagmi/react';

export default function BurnToken() {
    const { open } = useWeb3Modal();
    const { address, isConnecting, isDisconnected } = useAccount();
    const contract = useContract();
    const { data: signer } = useWalletClient();
    const publicClient = usePublicClient();
    const burnContractAdd = lycanChainBeta.contracts.BURN_PROTOCOL_CONTRACT;


    const [currentMode, setcurrentMode] = useState("swap");
    const [inputAmt, setinputAmt] = useState(0);
    const [tokenBurnAmt, setTokenBurnAmt] = useState(0);
    const [tokenEarnAmt, setTokenEarnAmt] = useState(0);
    const [lycFeeAmt, setLycFeeAmt] = useState(0);
    const [openToDropDown, setOpenToDropDown] = useState(false);
    const [outputToken, setoutputToken] = useState(0);
    const [enableSwap, setEnableSwap] = useState(true);
    const [liqMode, setLiqMode] = useState("native");
    const [inputTokenAddressLiq, setInputTokenAddressLiq] = useState("");
    const [inputTokenAddressValid, setinputTokenAddressValid] = useState(false);
    const [inputTokenAddressListed, setinputTokenAddressListed] = useState(false);
    const [inputBurnRateLiq, setInputBurnRateLiq] = useState(0);
    const [inputRewardRateLiq, setInputRewardRateLiq] = useState(0);
    const [inputMinBurnAmtLiq, setInputMinBurnAmtLiq] = useState(0);
    const [inputMaxBurnAmtLiq, setInputMaxBurnAmtLiq] = useState(0);
    const [inputLiquidityAmtLiq, setInputLiquidityAmtLiq] = useState(0);
    const [isListed, setIsListed] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [nativeBalance, setNativeBlanace] = useState(0);
    const [tokenSymbol, setTokenSymbol] = useState("");
    const [nativeSymbol, setNativeSymbol] = useState("");
    const [outputBalance, setOutputBalance] = useState(0);

    const result = useBalance({
        address: address,
        token: BURN_PROTOCOL_CONTRACT_BETA,
    });

    const nativeResult = useBalance({
        address: address,
    });

    const clearInputs = () => {
        setinputAmt(0);
        setTokenBurnAmt(0);
        setTokenEarnAmt(0);
        setLycFeeAmt(0);
    };

    useEffect(() => {
        if (result.data) {
            setCurrentBalance(convertDividePrice(result.data.value));
            setTokenSymbol(result.data.symbol);
            console.log(
                `burn result balance ${result.data.value} ${result.data.symbol}`
            );

            console.log("burn balance :", convertDividePrice(result.data.value));
        }
        if (nativeResult.data) {
            setNativeBlanace(nativeResult.data.value.toString());
            setNativeSymbol(nativeResult.data.symbol);
            console.log(
                `native result balance ${nativeResult.data.value} ${nativeResult.data.symbol}`
            );
        }
    }, [address, result.data, nativeResult.data]);

    useEffect(() => {
        console.log("input amt ", inputAmt);
        if (inputAmt > 0) {
            calculateStats();
        }
    }, [inputAmt]);

    const outputTokenBal = useBalance({
        address: address,
        token: tokenList.at(outputToken).tokenAddress,
    });

    useEffect(() => {
        setToToken();
    }, [outputToken]);

    useEffect(() => {
        setOutputBalance(convertDividePrice(outputTokenBal?.data?.value));
    }, [outputTokenBal]);

    const setToToken = () => {
        setOpenToDropDown(false);
        console.log("output token balance ", outputTokenBal?.data?.value);
        setOutputBalance(convertDividePrice(outputTokenBal?.data?.value));
        getListingStats();
    };

    const calculateStats = async () => {
        console.log("input amt in calc ", inputAmt);

        const burnAmt = await contract.read.swapBurningAmount([
            tokenList.at(outputToken).tokenAddress,
            toWei(inputAmt),
        ]);

        const rewardAmt = await contract.read.swapUserRewardAmount([
            tokenList.at(outputToken).tokenAddress,
            toWei(inputAmt),
        ]);

        const adminFee = await contract.read.swapAdminFee([
            tokenList.at(outputToken).tokenAddress,
            toWei(inputAmt),
        ]);

        setTokenBurnAmt(convertDividePrice(burnAmt));
        setTokenEarnAmt(convertDividePrice(rewardAmt));
        setLycFeeAmt(convertDividePrice(adminFee));
    };

    const getListingStats = async () => {

        try {
            const value = await contract.read.getListingStatistics([tokenList.at(outputToken).tokenAddress]);
            if (value) {
                console.log("listing statistics: ", value)
            } else {
                console.log('errororor')
            }
        } catch (error) {
            console.log("listing statistics: ", error)
        }
    };

    const onSwap = async () => {
        if (!address) {
            open()
            return;
        }

        try {
            const value = await contract.write.swapBURNToken([tokenList.at(outputToken).tokenAddress, toWei(inputAmt)]);
            if (value) {
                console.log("listing statistics : ", value);
            } else {
                console.log("value");
            }
        } catch (error) {
            console.log("reward was taken error: ", error);
        }
    };

    const handleCreateListing = async () => {
        console.log('000000000000')
        if (liqMode === "native") {
            createListingForNative();
        } else {
            getApproval(inputTokenAddressLiq);
        }
    };

    const handleAddLiq = async () => {
        if (liqMode === "native") {
            addLiquidityForNative();
        } else {
            addLiquidityForToken();
        }
    };

    const createListingForNative = async () => {
        console.log("add liq inputs : ", {
            contract: inputTokenAddressLiq,
            burnrate: toWei(inputBurnRateLiq),
            rewardRate: toWei(inputRewardRateLiq),
            minburn: toWei(inputMinBurnAmtLiq),
            maxBurn: toWei(inputMaxBurnAmtLiq),
            liqAmt: toWei(inputLiquidityAmtLiq),
        });

        try {
            const value = await contract.write
                .createListingInProtocolForNative(
                    [
                        inputTokenAddressLiq,
                        toWei(inputBurnRateLiq),
                        toWei(inputRewardRateLiq),
                        toWei(inputMinBurnAmtLiq),
                        toWei(inputMaxBurnAmtLiq),
                        toWei(inputLiquidityAmtLiq),
                    ],
                    { value: toWei(inputLiquidityAmtLiq) }
                );
            if (value) {
                console.log("listing statistics : ", value);
            } else {
                console.log("");
            }

        } catch (error) {
            console.error("error: ", error);
        }
    };

    const getApproval = async (contractAddress) => {
        const tokenCon = await getContract({
            address: contractAddress,
            abi: erc20Abi,
            client: { public: publicClient, wallet: signer },
        });

        console.log("token contract ", tokenCon);

        console.log("contract address input ", contractAddress);

        const decimal = await tokenCon.read.decimals();
        console.log("decimal : ", decimal);

        //decimal should be 18 or 6
        const amountInWei =
            decimal === 18
                ? toWei(inputLiquidityAmtLiq)
                : toMwei(inputLiquidityAmtLiq);

        console.log("mwei amt : ", amountInWei);

        console.log("approval inputs : ", {
            contractadd: burnContractAdd,
            amt: toWei(inputLiquidityAmtLiq),
        });
        // onAddLiq();

        try {
            const value = await tokenCon.write.approve([burnContractAdd, toWei(inputLiquidityAmtLiq)]);
            if (value) {
                console.log("approval : ", value);

                setTimeout(createListingToken, 4000);
            } else {
                console.log("");
            }
        } catch (error) {
            console.error("reward was taken error: ", error);
        }
    };

    const addLiquidityForNative = async () => {
        if (!address) {
            open()
            return;
        }
        try {
            const value = await contract.write.addLiquidityForNative([toWei(inputLiquidityAmtLiq)], { value: toWei(inputLiquidityAmtLiq), });
            if (value) {
                console.log("listing statistics : ", value);
            } else {
                console.log("");
            }
        } catch (error) {
            console.error("error: ", error);
        }
    };

    const addLiquidityForToken = async () => {
        // get approval before calling
        if (!address) {
            open()
            return;
        }
        try {
            const value = await contract.write.addLiquidityForToken([inputTokenAddressLiq, toWei(inputLiquidityAmtLiq)], { value: toWei(inputLiquidityAmtLiq), });
            if (value) {
                console.log("listing statistics : ", value);
            } else {
                console.log("");
            }
        } catch (error) {
            console.error("error: ", error);
        }
    };

    const createListingToken = async () => {
        console.log("add liq inputs : ", {
            contract: inputTokenAddressLiq,
            burnrate: toWei(inputBurnRateLiq),
            rewardRate: toWei(inputRewardRateLiq),
            minburn: toWei(inputMinBurnAmtLiq),
            maxBurn: toWei(inputMaxBurnAmtLiq),
            liqAmt: toWei(inputLiquidityAmtLiq),
        });

        try {
            const value = await contract.write.createListingInProtocol([inputTokenAddressLiq, toWei(inputBurnRateLiq), toWei(inputRewardRateLiq), toWei(inputMinBurnAmtLiq),
                toWei(inputMaxBurnAmtLiq), toWei(inputLiquidityAmtLiq),]);
            if (value) {
                console.log("listing created : ", value);
            } else {
                console.log("");
            }
        } catch (error) {
            console.error("listing error: ", error);
        }
    };

    useEffect(() => {
        if (isValidTokenAddress(inputTokenAddressLiq)) {
            console.log("valid token address");
            setinputTokenAddressValid(true);
            isTokenListed(inputTokenAddressLiq);
        } else {
            console.log("invalid token address");
            setinputTokenAddressValid(false);
        }
    }, [inputTokenAddressLiq]);

    const isTokenListed = async () => {
        try {
            const isListedTokenListed = await contract.read.isTokenIntialized([
                inputTokenAddressLiq,
            ]);
            setIsListed(isListedTokenListed);
            console.log("is listed : ", isListed);
        } catch (error) {
            console.log(error, 'erororor')
        }
        // if listed is true show only liquidty amoount input - else show all
        // token is native - call add liquidity native function
    };

    return (
        <>
            <TopBar />
            <Header />
            <section className="min-h-[calc(100vh-212px)] w-full px-[16px]">
                <div className="max-w-[520px] mx-auto w-full flex flex-col items-center gap-[30px] self-stretch sm-480:py-[38px] py-[48px] ">
                    <div className="flex flex-col items-center gap-2 self-stretch ">
                        <p className="text-dark-col sm-480:text-[36px] text-[32px] font-medium sm-480:leading-[92%] leading-[100%] tracking-[-1.08px] capitalize">Convert Burn Token</p>
                        <p className="text-dark-col sm-480:text-[16px] text-[14px] font-medium sm-480:leading-[120%] leading-[100%] tracking-[-0.32px] capitalize">Convert your burn token Into LYC</p>
                    </div>
                    <div className="lg:max-w-screen-md  overflow-hidden border border-gray-100 bg-gray-50 p-1 rounded-[63px]">
                        <ul className="flex items-center gap-2 text-sm font-medium">
                            <li className="flex-1">
                                <a
                                    onClick={() => setcurrentMode("swap")}
                                    className={`text-gra cursor-pointer font-semibold text-nowrap text-[18px] w-[179px] h-[63px] rounded-[63px] relative flex items-center justify-center gap-2 px-3 py-2 ${currentMode === "swap"
                                        ? "bg-dark-org shadow hover:bg-dark-org  text-main-bg"
                                        : "hover:bg-dark-org hover:text-main-bg bg-main-bg text-dark-org"
                                        }`}
                                >
                                    Swap
                                </a>
                            </li>
                            <li className="flex-1">
                                <a
                                    onClick={() => setcurrentMode("liquidity")}
                                    className={`text-gra cursor-pointer text-nowrap font-semibold text-[18px] w-[179px] h-[63px] rounded-[63px] relative flex items-center justify-center gap-2 px-3 py-2 ${currentMode === "liquidity"
                                        ? "bg-dark-org shadow hover:bg-dark-org  text-white"
                                        : "hover:bg-dark-org hover:text-white bg-main-bg text-dark-org"
                                        }`}
                                >
                                    Add Liquidity
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={`${currentMode === "swap" ? "visible" : "hidden"} flex flex-col items-center gap-2 justify-center self-stretch`}>
                        <div className="flex flex-col items-center gap-2 self-stretch relative justify-center ">
                            <div className="flex sm-480:p-[30px] p-[16px] flex-col items-start gap-4 sm-480:rounded-[30px] rounded-[16px] bg-white self-stretch">
                                <div className="flex px-[16px] py-[4px] justify-center  items-center gap-2.5 rounded-[80px] bg-main-bg text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">From</div>
                                <div className="flex flex-col gap-2 self-stretch">
                                    <div className="grid grid-cols-2 ">
                                        <div className="flex items-center gap-2 ">
                                            <img className="sm-480:w-fit w-[48px]" src="assets/images/burn.svg" alt="burn" />
                                            <p className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] capitalize">BURN</p>
                                        </div>
                                        <input className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] p-0 focus:ring-0  text-right capitalize border-none "
                                            type="number"
                                            placeholder={"00"}
                                            disabled={false}
                                            id="fromAmt"
                                            onChange={(e) => setinputAmt(e.target.value)}
                                            name="fromAmt"
                                            value={inputAmt} />
                                    </div>
                                    <div className="grid grid-cols-2  gap-2.5">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize"></p>
                                        <p className="text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize cursor-pointer" onClick={clearInputs}>Clear</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex sm-480:p-[30px] p-[16px] flex-col items-start gap-4 sm-480:rounded-[30px] rounded-[16px] bg-white self-stretch">
                                <div className="flex px-[16px] py-[4px] justify-center  items-center gap-2.5 rounded-[80px] bg-main-bg text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">To</div>
                                <div className="flex flex-col gap-2 self-stretch">
                                    <div className="grid grid-cols-2">
                                        <div className="relative">
                                            <div className="flex items-center gap-2 " onClick={() => setOpenToDropDown(!openToDropDown)}>
                                                <img className="sm-480:w-fit w-[48px]" src={tokenList.at(outputToken).icon || "assets/images/lyc.svg"} alt="lyc" />
                                                <p className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] capitalize">{tokenList.at(outputToken).tokenSymbol}</p>

                                                <svg viewBox="0 0 1024 1024" fill="currentColor" height="12px" width="12px">
                                                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                                                </svg>

                                            </div>
                                            <div
                                                id=""
                                                className={`absolute w-full mt-1 overflow-hidden right-1 top-full min-w-max shadow rounded-[20px] ${openToDropDown ? "visible" : "hidden"
                                                    } bg-white border border-orange-400 transition delay-75 ease-in-out z-10`}
                                            >
                                                <ul className="block w-full text-gray-900">
                                                    {tokenList.map((item, i) => (
                                                        <li key={i}>
                                                            <a
                                                                onClick={() => { setoutputToken(i); setOpenToDropDown(!openToDropDown) }}
                                                                className="block px-3 py-2 hover:bg-orange-100"
                                                            >
                                                                <TokenListItem data={item} />
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <input disabled className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] p-0 focus:ring-0  text-right capitalize border-none " type="text" value={tokenEarnAmt} placeholder={"00"} />
                                    </div>
                                    {/* <div className="grid grid-cols-2  gap-2.5">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Lycanchain</p>
                                        <p className="text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">~$ 50532.2 <span className="text-dark-org ml-2">(-0.28%)</span></p>
                                    </div> */}
                                </div>
                                <div className="flex p-4 flex-col justify-center items-center gap-2 self-stretch rounded-[10px] bg-main-bg">
                                    <div className="grid grid-cols-2 self-stretch">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Burn Token</p>
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">{tokenBurnAmt} BURN</p>
                                    </div>
                                    <div className="grid grid-cols-2 self-stretch">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Token Earn</p>
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">{tokenEarnAmt}</p>
                                    </div>
                                    <div className="grid grid-cols-2 self-stretch">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Platform Fee</p>
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">{lycFeeAmt}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute sm-480:top-[38%] top-[36%] left-1/2 cursor-pointer transform translate-x-[-50%] translate-y-[-50%]">
                                <img src="assets/images/downarrow.svg" alt="Convert" />
                            </div>
                        </div>
                        <button className="relative overflow-hidden custom-btn flex px-[30px] py-[20px] justify-center items-center rounded-[60px] bg-dark-col text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase" disabled={!enableSwap} onClick={onSwap}>
                            Convert
                            <div className="shape3" />
                            <div className="shape4" />
                        </button>

                    </div>

                    <div className={`${currentMode === "liquidity" ? "visible" : "hidden"} flex flex-col items-center gap-2 justify-center self-stretch`}>

                        <div className="flex flex-col items-center gap-2 self-stretch relative justify-center ">
                            <div className="flex sm-480:p-[30px] p-[16px] flex-col items-start gap-4 sm-480:rounded-[30px] rounded-[16px] bg-white self-stretch">
                                <div className="lg:max-w-screen-md  overflow-hidden">
                                    <ul className="flex items-center gap-2 text-sm font-medium">
                                        <li className="flex-1">
                                            <a
                                                onClick={() => setLiqMode("native")}
                                                className={`text-gra px-[39px] text-[18px] py-[10px] cursor-pointer rounded-[84px] relative flex items-center justify-center gap-2 ${liqMode === "native"
                                                    ? "bg-dark-col  shadow hover:bg-dark-col  text-white"
                                                    : "hover:bg-dark-col hover:text-white bg-main-bg"
                                                    }`}
                                            >
                                                Native
                                            </a>
                                        </li>
                                        <li className="flex-1">
                                            <a
                                                onClick={() => setLiqMode("token")}
                                                className={`text-gra px-[39px] text-[18px] py-[10px] cursor-pointer rounded-[84px] relative flex items-center justify-center gap-2 ${liqMode === "token"
                                                    ? "bg-dark-col  shadow hover:bg-dark-col  text-white"
                                                    : "hover:bg-dark-col hover:text-white bg-main-bg"
                                                    }`}
                                            >
                                                Token
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className='block w-full'>
                                    <label className='text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize'>Token Address</label>
                                    <input type='text' className='h-[55px] px-[16px] w-full  py-[10px] bg-main-bg text-[18px] border-0 rounded-[10px] focus:border-0 focus:shadow-none'
                                        disabled={false}
                                        id="tokenAddressLiq"
                                        onChange={(e) => setInputTokenAddressLiq(e.target.value)}
                                        name="TokenAddress"
                                        value={inputTokenAddressLiq}
                                    />
                                    {inputTokenAddressLiq.length > 8 &&
                                        <div className="w-full flex justify-end">
                                            <p
                                                className={`text-xs text-red-600`}
                                            >
                                                {inputTokenAddressValid ? "" : "Invalid Address"}
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div className='grid gap-2 grid-cols-2'>
                                    <div className='block w-full'>
                                        <label className='text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize'>Burn Rate</label>
                                        <input type='text' className='h-[55px] px-[16px] w-full  py-[10px] bg-main-bg text-[18px] border-0 rounded-[10px] focus:border-0 focus:shadow-none'
                                            disabled={false}
                                            id="burnrate"
                                            onChange={(e) => setInputBurnRateLiq(e.target.value)}
                                            name="burnrate"
                                            value={inputBurnRateLiq}
                                        />
                                    </div>
                                    <div className='block w-full'>
                                        <label className='text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize'>Reward Rate</label>
                                        <input type='text' className='h-[55px] px-[16px] w-full  py-[10px] bg-main-bg text-[18px] border-0 rounded-[10px] focus:border-0 focus:shadow-none'
                                            disabled={false}
                                            id="rewardrate"
                                            onChange={(e) => setInputRewardRateLiq(e.target.value)}
                                            name="rewardrate"
                                            value={inputRewardRateLiq}
                                        />
                                    </div>
                                </div>
                                <div className='grid gap-2 grid-cols-2'>
                                    <div className='block w-full'>
                                        <label className='text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize'>Min. Burn Amount</label>
                                        <input type='text' className='h-[55px] px-[16px] w-full  py-[10px] bg-main-bg text-[18px] border-0 rounded-[10px] focus:border-0 focus:shadow-none'
                                            disabled={false}
                                            id="minburnamt"
                                            onChange={(e) => setInputMinBurnAmtLiq(e.target.value)}
                                            name="minburnamt"
                                            value={inputMinBurnAmtLiq}
                                        />
                                    </div>
                                    <div className='block w-full'>
                                        <label className='text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize'>Max. Burn Amount</label>
                                        <input type='text' className='h-[55px] px-[16px] w-full  py-[10px] bg-main-bg text-[18px] border-0 rounded-[10px] focus:border-0 focus:shadow-none'
                                            disabled={false}
                                            id="maxburnamt"
                                            onChange={(e) => setInputMaxBurnAmtLiq(e.target.value)}
                                            name="maxburnamt"
                                            value={inputMaxBurnAmtLiq}
                                        />
                                    </div>
                                </div>
                                <div className='block w-full'>
                                    <label className='text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize'>Liquidity Amount</label>
                                    <input type='text' className='h-[55px] px-[16px] w-full  py-[10px] bg-main-bg text-[18px] border-0 rounded-[10px] focus:border-0 focus:shadow-none'
                                        disabled={false}
                                        id="liqamt"
                                        onChange={(e) => setInputLiquidityAmtLiq(e.target.value)}
                                        name="liqamt"
                                        value={inputLiquidityAmtLiq}
                                    />
                                </div>
                            </div>

                        </div>
                        <button className="relative overflow-hidden custom-btn flex px-[30px] py-[20px] justify-center items-center rounded-[60px] bg-dark-col text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase" onClick={() =>
                            !isListed ? handleAddLiq() : handleCreateListing()}>
                            Proceed
                            <div className="shape3" />
                            <div className="shape4" />
                        </button>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
};

const TokenListItem = ({ data }) => {
    return (
        <div className="w-full flex gap-2">
            {/* <div className="rounded-full border border-orange-700 w-10 h-10"> */}
            <img className="sm-480:w-fit w-[30px] max-w-[40px]" src={data.icon} alt={data.tokenName} />
            {/* </div> */}
            <div className="flex flex-col cursor-pointer">
                <div className="flex items-center gap-2">
                    <p className="font-semibold">{data.tokenSymbol}</p>
                </div>
                <p className="text-[10px]">{data.tokenName}</p>
            </div>
        </div>
    );
};
